<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-spacer />
      <v-col
        cols="auto"
        class="d-flex flex-row-reverse align-center date-picker-col">
        <v-btn
          class="ml-2"
          color="secondary"
          @click="toggleCreateForm(false)">
          {{ showCreate ? "Close" : "Create" }}
        </v-btn>
        <date-range-picker
          v-if="!showCreate"
          v-model="dateRange"
          :loading="getting"
          @input="dateChanged()" />
      </v-col>
    </v-row>
    <v-row
      v-if="showCreate"
      class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <visitors-report-form @closeForm="toggleCreateForm($event)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1 class="page-title">
              Visitors Dashbroad
            </h1>
          </v-col>
          <v-spacer />
        </v-row>

        <visitors-report
          :loading="getting"
          :reports="reports" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import VisitorsProvider from '@/resources/VisitorsProvider'
import DateRangePicker from '../components/DatesPicker.vue'
import VisitorsReport from '../components/VisitorsReport.vue'
import VisitorsReportForm from '../components/forms/VisitorsReportForm.vue'

const VisitorsService = new VisitorsProvider()

export default {
  components: {
    DateRangePicker,
    VisitorsReport,
    VisitorsReportForm
  },
  data () {
    return {
      reports: [],
      showCreate: false,
      dateRange: [],
      getting: false
    }
  },
  async created () {
    this.initDefaultDate()
    await this.getReports()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    initDefaultDate () {
      const startDate = this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD')
      const endDate = this.$dayjs().format('YYYY-MM-DD')
      this.dateRange = [startDate, endDate]
    },
    async getReports () {
      try {
        this.getting = true
        const { data } = await VisitorsService.getVisitorsReport(this.dateRange)
        this.reports = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.getting = false
      }
    },
    toggleCreateForm (isCreated) {
      this.showCreate = !this.showCreate

      if (isCreated) this.getReports()
    },
    dateChanged () {
      this.getReports()
    }
  }
}
</script>

<style scoped>

.page-title {
  text-decoration: underline;
}
</style>
