import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class VisitorsProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MERLIN_API)
  }

  getVisitorsReport (dateRange) {
    this.setHeader(getAuthToken())
    return this.get(`/visitors-report?startDate=${dateRange[0]}&endDate=${dateRange[1]}`)
  }

  createVisitorsReport (reportForm) {
    this.setHeader(getAuthToken())
    return this.post('visitors-report', reportForm)
  }
}

export default VisitorsProvider
