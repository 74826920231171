<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="page-title">
          Create Visitors Report
        </h1>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        cols="4"
        align="right">
        Report Date :
      </v-col>
      <v-col
        cols="5">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.reportDate"
              label="Report Date"
              prepend-icon="mdi-calendar"
              readonly
              dense
              v-bind="attrs"
              v-on="on" />
          </template>
          <v-date-picker
            v-model="form.reportDate"
            @input="dateMenu = false" />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        align="right">
        Branches :
      </v-col>
      <v-col v-if="loading">
        <v-progress-circular indeterminate />
      </v-col>
      <v-col
        v-else
        cols="4">
        <v-row
          v-for="branch in form.branches"
          :key="branch.id">
          <v-col>
            {{ branch.name }}
          </v-col>
          <v-col>
            <v-text-field
              v-model="branch.amount"
              type="number"
              min="0"
              label="Amount"
              dense
              outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :loading="loading"
              color="secondary"
              @click="confirm()">
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import WarehouseProvider from '@/resources/WarehouseProvider'
import VisitorsProvider from '@/resources/VisitorsProvider'

const WarehouseService = new WarehouseProvider()
const VisitorsService = new VisitorsProvider()

export default {
  data () {
    return {
      loading: false,
      dateMenu: false,
      headers: [
        {
          text: 'Branch',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Amount',
          align: 'center',
          sortable: false,
          value: 'amount'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'action'
        }
      ],
      form: {
        reportDate: '',
        branches: []
      }
    }
  },
  async created () {
    this.initDate()
    await this.initBranches()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    initDate () {
      this.form.reportDate = this.$dayjs().format('YYYY-MM-DD')
    },
    async initBranches () {
      try {
        this.loading = true
        const { data } = await WarehouseService.getWarehouse()
        this.form.branches = data.map((branch) => {
          const mapped = {
            id: branch.id,
            name: branch.name,
            amount: 0
          }

          return mapped
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async confirm () {
      try {
        this.loading = true

        this.form.branches = this.form.branches.map((branch) => {
          const mapped = {
            id: branch.id,
            name: branch.name,
            amount: (+branch.amount)
          }

          return mapped
        })

        const response = await VisitorsService.createVisitorsReport(this.form)

        if (response) {
          this.setSnackbar({
            value: true,
            message: 'Created report success.',
            type: 'success'
          })

          this.$emit('closeForm', true)
        } else {
          const error = {
            code: 400,
            message: 'Failed to create'
          }

          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

.page-title {
  text-decoration: underline;
}
</style>
