<template>
  <v-container>
    <v-row justify="center">
      <div class="table-wrap ma-2 mt-4">
        <v-data-table
          :loading="loading"
          :headers="tableHeaders"
          :items="contents"
          hide-default-footer
          dense
          :items-per-page="20"
          class="elevation-1">
          <template v-slot:item="{ item, headers }">
            <tr>
              <td
                v-for="(row, index) in headers"
                :key="row.value"
                class="text-center">
                <span>
                  {{ (index === 0 || index === headers.length - 1) ? item[row.value] : item.branches[row.value] }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    reports: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      row_count: 0,
      tableHeaders: []
    }
  },
  computed: {
    contents () {
      const contents = []
      const totalCols = {}

      this.reports.forEach((report) => {
        const branches = {}
        let totalRow = 0

        report.branches.forEach((branch) => {
          branches[`_${branch.id}`] = branch.amount
          totalRow += +branch.amount
          if (`_${branch.id}` in totalCols) {
            totalCols[`_${branch.id}`] += +branch.amount
          } else {
            totalCols[`_${branch.id}`] = +branch.amount
          }
        })

        contents.push({
          reportDate: report.reportDate,
          branches,
          totalRow
        })
      })

      contents.push({
        reportDate: 'Total',
        branches: totalCols,
        totalRow: Object.values(totalCols).reduce((sum, col) => sum + col, 0)
      })
      this.initReportTable()

      return contents
    }
  },
  mounted () {
    this.initReportTable()
  },
  methods: {
    initReportTable () {
      this.tableHeaders = [
        {
          text: 'วันที่',
          sortable: true,
          value: 'reportDate',
          width: '150px',
          align: 'center'
        }
      ]

      if (this.reports[0]?.branches) {
        this.reports[0].branches.forEach((branch) => {
          this.tableHeaders.push({
            text: branch.name,
            sortable: false,
            value: `_${branch.id}`
          })
        })
      }

      this.tableHeaders.push({
        text: 'รวม',
        sortable: true,
        value: 'totalRow'
      })
    }
  }
}
</script>

<style scoped>
.page-title {
  text-decoration: underline;
}

.table-wrap {
  width: 90%;
}

</style>
